import React from "react";
import { Helmet } from "react-helmet";

const HelmetItems = (props) => {
  const title = props.title ? `${props.title} | Carbonaires` : "Carbonaires";
  const description =
    props.description ||
    "Carbonaires is a London based, values-driven carbon finance specialist. We source, diligence and facilitate carbon dioxide removal project investment opportunities for our client base using a highly skilled team of scientists, engineers and financial experts as well as tech and A.I. Our team, theme and tech are all unique.";
  const img = props.img || "https://carbonaires.com/carbonaires-logo.png";

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <meta property="og:image" content={img} />
    </Helmet>
  );
};

export default HelmetItems;
